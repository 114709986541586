<template>
  <content-box v-if="showFreeTrialAnnouncement" :title="title">
    <h3 class="text-center">Learn the Sport of Wrestling!</h3>
    <div class="my-4">
      <p>
        <span class="font-weight-bold text-primary"> October is FREE! </span>
        Open to all boys & girls, Kindergarten-8th grade, brand new and
        experienced wrestlers.
      </p>
      <p>
        We are pleased to offer a
        <span class="font-weight-bold text-primary">FREE</span>, no commitment
        trial of our program. Join one of New England's top youth town teams.
      </p>
      <p>
        Session runs Monday and Wednesday evenings in
        <span class="font-weight-bold text-primary">October</span>
        at the Hillview Montessori.
      </p>
      <p>
        To participate,
        <span class="font-weight-bold text-primary">register</span> your child
        using our registration page. At the end of the session your child is
        registered for our program. Registration fees are due at this time.
      </p>
      <p>
        If your child doesn't continue, inform our coaching staff at the end of
        the session. That's it.
      </p>
      <p>Hope to see you soon!</p>
    </div>
    <registration-button></registration-button>
  </content-box>
</template>

<script>
import commonFunctions from '@/commons/registrationFunctions'

import ContentBox from '@/components/contentBox'
import RegistrationButton from '@/components/registration/registrationButton'
import seasonFunctions from '@/commons/seasonFunctions'

export default {
  name: 'freeTrial',
  components: { RegistrationButton, ContentBox },
  computed: {
    showFreeTrialAnnouncement() {
      return commonFunctions.isFreeTrialOpen()
    },
    title() {
      return seasonFunctions.toString() + ' Registration'
    },
  },
}
</script>

<style lang="scss" scoped></style>
